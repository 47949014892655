import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { UpdateService } from '../../services/UpdateService';
import { dateTimeFormatter } from './../../library/formatters';

export const JobTasks = ({getTasks, jobData}) => {
    const gridRef = useRef();
    const defaultPageSize = 25;
    const onGridReady = useCallback( async (params) => {
        const dataSource = {
          rowCount: undefined,
          getRows: getTasks
        };
        console.log(dataSource);
        params.api.setGridOption('datasource', dataSource);
    });

    const pageSizeOptions=[15, 25, 50, 100]

    const refreshTable = () => {
        gridRef.current.api.refreshInfiniteCache();
    }

    const [colDefs, setColDefs] = useState([
        {field: "index", headerName: "Task Id", filter: 'agTextColumnFilter', sortable: false, filter:false, flex:1},
        {field: "state", headerName: "Task State", filter: 'agTextColumnFilter', sortable: false, filter:false, flex:1},
        {field: "created", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1},
        {field: "started", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1},
        {field: "completed", filter: false, sortable: true, valueFormatter: dateTimeFormatter, flex:1},
        {field: "retryAttempts", headerName: "Retry Attempts", filter: false, sortable: false, flex:1},
    ]);

    const defaultColDef = useMemo(() => {
        return {
          flex: 1
        };
    }, []);

    return(
    <>
        <div className='ag-theme-quartz half-page-table table-align'>
            <AgGridReact
                suppressClickEdit={true}
                ref = {gridRef}
                pagination={true}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableClickSelection={false}
                rowModelType={'infinite'}
                onGridReady={onGridReady}
                paginationPageSize={defaultPageSize}
                paginationPageSizeSelector={pageSizeOptions}
                acheOverflowSize={2}
                maxConcurrentRequests={2}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
            />
        </div>
        <UpdateService updateDataFunction={refreshTable} gridRef={gridRef}/> 
    </>
    )
}