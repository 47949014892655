import React from 'react';
import { Button } from 'react-bootstrap';

export default (props) => {
  
    
    const entryPoint = props.entryPoint;
    const handleJobOutputEntry = (e) => {
        props.handleEntryPoint(props);
    }

    // Button className="jobActionDisabled" name = "start" label = "draft" kind="ghost" disabled renderIcon={Play} hasIconOnly onClick={e => handleJobAction("start", handleChangeJobState)}></Button>;
    
    return(
        props.data[entryPoint] ? <Button name="resultEntryPoint" kind = "ghost" label="fileNotSelected" onClick={e => handleJobOutputEntry(e)}><i class="bi bi-x"/> </Button> : <Button className = "selectedFile" label="fileSelected" name="resultEntryPoint" kind = "ghost" onClick={e => handleJobOutputEntry(e)}>
        <i class="bi bi-check2"/>
    </Button>
    )
};