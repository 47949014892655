import React, {useCallback} from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { updateJobState, downloadJobFiles, copyJob } from '../../apis/jobApi';
const JobStateAction = ({rowProps}) => 
{
    const handleJobStateChange =  async(newJobState) => {
        const gridRef = rowProps.gridRef;
        const response = await updateJobState(rowProps.data.id, newJobState);
        rowProps.node.data = response
        gridRef.current.api.refreshCells({rowNodes: [rowProps.node], force: true, columnIds: ["state"]}); 
    }
    
    //replace IconButton with button
    if(rowProps.data === undefined)
    {
        return <Button name="undefinedState" className="jobActionAlert button-table" label="test" iconDescription="Job State Undefined"><i class="bi bi-exclamation-circle"/></Button>;
        
    }
    else
    {
        const jobState = rowProps.data["actions"] === undefined ? rowProps.data["state"] : rowProps.data["actions"];
        switch(jobState){
            case "Draft":
            case "Staging":
            case "Pausing":
            case "Paused":
                return <Button className="jobActionStart button-table" name = "start" label="start" iconDescription="Play" hasIconOnly onClick={() => handleJobStateChange("start")}><i class="bi bi-play-fill"/></Button>;
            case "Queueing":
            case "Queued":
                return <Button className="jobAction  button-table" name = "pause" label="starting"  iconDescription="Pause"  hasIconOnly onClick={() => handleJobStateChange("pause")}><i class="bi bi-pause-fill"/></Button>;
            case "Stopping":
                return <Button className="jobActionPaused  button-table" name="stop" label="stopping" iconDescription="Stop Disabled"  kind="ghost" disabled hasIconOnly><i class="bi bi-stop-fill"></i></Button>;
            case "Stopped":
            case "Completed":
            case "Failed":
            case "Archived":
            case "Purging":
            case "Purged":
                return <Button className="jobActionDisabled  button-table" name = "start" label = "draft" iconDescription="Stop Disabled" kind="ghost" disabled hasIconOnly><i class="bi bi-dash"/></Button>;
            default:
                return <Button className="jobAction  button-table" name="pause" label="jobRunning" conDescription="Running" hasIconOnly onClick={() => handleJobStateChange("pause")}><i class="bi bi-pause-fill"/></Button>;
        }
        
        
    }
    
}

const StopJobState = ({rowProps}) =>
    {
    
        const handleJobStateChange =  async(newJobState) => {
            const gridRef = rowProps.gridRef;
            const response = await updateJobState(rowProps.data.id, newJobState);
            rowProps.node.data = response
            gridRef.current.api.refreshCells({rowNodes: [rowProps.node], force: true, columnIds: ["state"]}); 
        }
        if(rowProps.data === undefined)
        {
            return <Button label="undefined" iconDescription="undefined stop" className="jobActionDisabled button-table" disabled><i class="bi bi-exclamation-circle"/></Button>
        }
        else
        {
            const jobState = rowProps.data["actions"] === undefined ? rowProps.data["state"] : rowProps.data["actions"];
    
            switch(jobState){
                case "Stopped":
                case "Stopping":
                case "Completed":
                case "Failed":
                case "Draft":
                case "Archived":
                    return <Button className="button-table" variant="primary" onClick={() => handleJobStateChange("stop")} disabled><i className="bi bi-stop-fill"></i></Button>
                default:
                    return <Button className="button-table" variant="primary" onClick={() => handleJobStateChange("stop")}><i className="bi bi-stop-fill"></i></Button>
            }
        }
    }


const DeleteJob  = ({rowProps}) =>
{
    const jobData = rowProps.data;
    
    const handleDeleteJob = useCallback(async () => 
    {
        const response = await updateJobState(rowProps.data.id, "archive");
        rowProps.node.data = response
        rowProps.gridRef.current.api.refreshCells({rowNodes: [rowProps.node], force: true, columnIds: ["state"]});
    })
    if(jobData === undefined || jobData.state === "Archived")
    {
        return <Button className="button-table" variant="primary"><i className="bi bi-trash" disabled></i></Button>
    }
    else
    {
        return <Button className="button-table" variant="primary" onClick={handleDeleteJob} disabled><i className="bi bi-trash" disabled></i></Button>

    }
}

export const JobActions = (rowProps) => {


    const handleDownloadJob = async () => {
        await downloadJobFiles(rowProps.data.id);
    }

    const handleCopyJob = async () => {

        await copyJob(rowProps.data.id);
        rowProps.gridRef.current.api.refreshInfiniteCache()

    }

    return(
        <>
            <ButtonGroup size="sm">
                <JobStateAction  rowProps = {rowProps}/>
                <StopJobState rowProps = {rowProps}/>
                <Button className="button-table" variant="primary" disabled><i className="bi bi-download" onClick={handleDownloadJob}></i></Button>
                <DeleteJob rowProps={rowProps}/>
            </ButtonGroup>
        </>
    )
}