import { AgGridReact } from 'ag-grid-react';
import React, { useState, useRef, useMemo } from 'react';
import { getTextComparators } from './../../library/comparators';
import { dateTimeFormatter } from './../../library/formatters';
export const JobHistory = ({jobData}) => {

    const containerRef = useRef(null);
    const jobHistory = [];
    
    const textComparators = getTextComparators(); 
    const [colDefs, setColDefs] = useState([
        {field: "time", headerName: "Time", filter: 'agDateColumnFilter', valueFormatter: dateTimeFormatter, filter:false},
        {field: "executor", headerName: "Exectutor",filter: 'agTextColumnFilter', filterParams:{filterOptions : textComparators, maxNumConditions: 1},flex:1.2 },
        {field: "event", headerName: "Event", filter: 'agTextColumnFilter'},   
    ]);
    
    const defaultColDef = useMemo(() => {
        return {
        flex: 1
        };
    }, []);
  
    return <>
        <div className="ag-theme-quartz half-page-table table-align">
            <AgGridReact
                defaultColDef={defaultColDef}
                rowData={jobHistory}
                columnDefs={colDefs}
            />
        </div>
    </>
};

