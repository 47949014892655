export function handleJsonResponse(response) {
  handleResponse(response);
  return response.json();
  
}

export function handleFileUploadResponse(response) {
  handleResponse(response);
}

export function getFilterQuery(filters) {
  var filterQuery = "";
  if (!filters) {
    return filterQuery;
  }
  const filterKeys = Object.keys(filters);

  filterKeys.map((key) => {
    const value = filters[key];
    const filterValue = encodeURIComponent(value.filter);
    const comparator = value.type;
    filterQuery+= `&${key}=${comparator}${filterValue}`;
  
  });
  return filterQuery;
}

export function addSortingQuery(filterQuery, sorting)
{
  if(!sorting){
    return filterQuery;
  }

  const sortKeys = Object.keys(sorting);
  sortKeys.map((key) =>{

    const value = sorting[key];
    const sortValue = value.colId;
    const sortDirection = value.sort === "asc"? "": "-";
    
    filterQuery+='&sort=' + sortValue + sortDirection;

  })

  return filterQuery;
}

function handleResponse(response)
{
  
  if (!response.ok) {
    if (response.status === 401)
      window.location.replace("/Account/Login?ReturnUrl=" + encodeURIComponent(document.location.pathname));
    else if(response.status === 404)
    {
      throw new Error('Status Code: 404 Not Found');
    }
    throw new Error('Network response was not ok')
  }
}