import React, { useState, useMemo, useCallback, useRef } from 'react'; // React State Management
import { useParams } from 'react-router';
import {  Container, Button, Dropdown, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import { getJob, getJobFiles, getJobTasks } from '../apis/jobApi';
import { useQuery } from '@tanstack/react-query'

import { AddFileTable } from '../components/Files/AddFileTable';
import { JobHistory } from '../components/Jobs/JobHistory';
import { JobAccess } from '../components/Jobs/JobAccess';
import { JobProgress } from '../components/Jobs/JobProgress';
import { UpdateService } from '../services/UpdateService';
import { JobTasks } from '../components/Jobs/JobTasks';
import { JobAccessModal } from '../components/Jobs/JobAccessModal';
import { durationFormatter } from '../library/formatters';
export const JobPage = () => 
{
    const [openAccess, setOpenAccess] = useState(false);
    const { id } = useParams();
    const columns = { sm: 1, md: 1, lg: 1};
    const { isLoading, isError, data } = useQuery({
        queryKey: [`job`, id],
        queryFn: async () => await getJob(id),
        keepPreviousData : true
    });
    
    const defaultPageSize = 15;
    const pageSizeOptions= [5, 10, 15, 20];
    const [activeTab, setActiveTab] = useState('tasks');
    
    const getInputFiles = async (params) => {        
        const response = await getJobFiles(id, 'Input', params.startRow, params.endRow-params.startRow)
        .then(response => {
            return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }    
        params.successCallback(response.data, lastRow);
    }

    const getOutputFiles = async (params) => {

        const response = await getJobFiles(id, 'Output', params.startRow, params.endRow-params.startRow)
            .then(response => {
                return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }
        params.successCallback(response.data, lastRow);
    }

    const getTasks = async (params) => {
        
        const response = await getJobTasks(id, params.startRow, params.endRow-params.startRow)
            .then(response => {
                return {total: response.total, data: response.data.map((fileRow => fileRow.data))};
        });
                
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (response.total <= params.endRow) {
            lastRow = response.total;
        }
        params.successCallback(response.data, lastRow);
    }
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    
    const addFiles = async (files) => 
    {
        
    }
    return(
        <>
        {
            !isLoading && !isError && <>
            <h3 className="display-4">Job {id}</h3>
            <Container fluid className='p-1'>
                <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={{offset:10, span:2}} sm={{offset:10, span:2}} md={{offset:11, span:1}} lg={{offset:11, span:1}}>
                        <div className="right-button-container">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className='button-table'>
                                    <i class="bi bi-three-dots-vertical" aria-hidden="true"/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu role="menu">
                                    <Dropdown.Item onClick={() => setOpenAccess(true)} role="menuitem">Edit Access</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Container>            
            <JobAccessModal show={openAccess} setShow={setOpenAccess}/>
            <Container fluid className="p-5 bg-light">
                <Row>
                    <Col>
                        <div className="d-flex align-items-center"><strong className="me-2">Description</strong> {data.description}</div>
                        <div className="d-flex align-items-center"><strong className="me-2">Priority</strong> {data.priority}</div>
                        <div className="d-flex align-items-center"><strong className="me-2">State</strong> {data.state}</div>
                        <div className="d-flex align-items-center"><strong className="me-2">Created</strong> {data.created}</div>
                        <div className="d-flex align-items-center"><strong className="me-2">Average Task Duration</strong> {durationFormatter(data.taskSummary.averageDuration)}</div>
                        <div className="d-flex align-items-center"><strong className="me-2">Total Job Duration</strong> {durationFormatter(data.taskSummary.totalDuration)}</div>
                    
                    </Col>
                    <Col>
                        <Row>
                            <div>
                                {
                                data.taskGenerationEntryPoint?
                                    <div className="d-flex align-items-center"><strong className="me-2">Task Generation Entrypoint</strong> {data.taskGenerationEntryPoint?.file?.fileName}/{data.taskGenerationEntryPoint?.method}</div>:""
                                }
                            </div>
                            <div>
                                {
                                data.taskExecutionEntryPoint?
                                    <div className="d-flex align-items-center"><strong className="me-2">Task Execution Entrypoint</strong> {data.taskExecutionEntryPoint?.file?.fileName} / {data.taskExecutionEntryPoint?.method}</div>:""
                                }
                            </div>
                            <div>
                                {
                                data.resultGatheringEntryPoint?
                                    <div className="d-flex align-items-center"><strong className="me-2">Result Gathering Entrypoint</strong> {data.resultGatheringEntryPoint?.file?.fileName} / {data.resultGatheringEntryPoint?.method}</div>:""
                                }
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                    
                            <Accordion className="bg-light">
                                <Accordion.Item eventKey="0" className="bg-light">
                                    <Accordion.Header className="bg-light">Metadata</Accordion.Header>
                                    <Accordion.Body className="bg-light">
                                        <strong>Job Application:</strong> 0317
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Progress</Accordion.Header>
                                    <Accordion.Body>
                                        <JobProgress jobData={data}/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ul className="nav nav-tabs" id="jobTabs" role="tablist">
                <li class="nav-item">
                    <a className={`nav-link ${activeTab === 'tasks' ? 'active' : ''}`} class="nav-link active" id="home-tab" data-toggle="tab" href="#tasks" role="tab" aria-controls="tasks" onClick={() => handleTabClick('tasks')} aria-selected="true">
                        Tasks
                    </a>
                </li>
                <li class="nav-item">
                    <a className={`nav-link ${activeTab === 'inputs' ? 'active' : ''}`} onClick={() => handleTabClick('inputs')} class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        Input Files
                    </a>
                </li>
                <li class="nav-item">
                    <a className={`nav-link ${activeTab === 'outputs' ? 'active' : ''}`} onClick={() => handleTabClick('outputs')} class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        Output Files
                    </a>
                </li>
                <li class="nav-item">
                    <a className={`nav-link ${activeTab === 'history' ? 'active' : ''}`} onClick={() => handleTabClick('history')} class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        History
                    </a>
                </li>        
            </ul>
            <div class="tab-content" id="jobTabContent">
                <div  className={`tab-pane ${activeTab === 'tasks' ? 'active' : ''}`} id="tasks" role="tabpanel" aria-labelledby="task-tab">
                    <Container fluid>
                        <Row>
                            <JobTasks getTasks={getTasks} jobData={data}/>
                        </Row>
                    </Container>
                </div>
                <div className={`tab-pane ${activeTab === 'inputs' ? 'active' : ''}`} id="inputs" class="tab-pane fade" role="tabpanel" aria-labelledby="profile-tab">
                    <Container fluid>
                        <AddFileTable tableSize={'half-page-table'} fileTableId={"input-files"} getRows={getInputFiles} addFiles={addFiles} additionalColumns={[]} defaultPageSize={defaultPageSize} pageSizeSelector={pageSizeOptions} addButtonConfiguration= {{disableAdd: data.state !== 'Draft', hideAdd: false,}}/>
                    </Container>
                </div>
                <div className={`tab-pane ${activeTab === 'outputs' ? 'active' : ''}`} id="outputs" class="tab-pane fade" role="tabpanel" aria-labelledby="profile-tab">
                    <Container fluid>
                        <AddFileTable tableSize={'half-page-table'} fileTableId={"output-files"} getRows={getOutputFiles} additionalColumns={[]} defaultPageSize={defaultPageSize} pageSizeSelector={pageSizeOptions} addButtonConfiguration={{disableAdd: data.state !== 'Draft', hideAdd: false,}}/>
                    </Container>
                </div>
                <div className={`tab-pane ${activeTab === 'history' ? 'active' : ''}`} id="history" class="tab-pane fade" role="tabpanel" aria-labelledby="profile-tab">
                    <Container fluid>                    
                        <Row>
                            <JobHistory jobData={data}/>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* {activeTab === 'history' && <JobHistory jobData={data}/>} */}
            </>
        }
        </>
    )
}